import CountryService from "@/services/CountryService";
import store from './../store';

export class MyUploadAdapter {
  loader: any
  xhr: XMLHttpRequest | undefined;
  url: string;

  constructor( loader: any, url: string ) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    // Set the upload url
    this.url = url;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file
      .then( (file: any) => new Promise( ( resolve, reject ) => {
        this._initRequest();
        this._initListeners( resolve, reject, file );
        this._sendRequest( file );
      } ) );
  }

  // Aborts the upload process.
  abort() {
    if ( this.xhr ) {
      this.xhr.abort();
    }
  }

  // Initialises the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = this.xhr = new XMLHttpRequest();
    const token = store.state.user.accessToken

    xhr.open( 'POST', this.url, true )
    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
    xhr.withCredentials = true
    xhr.responseType = 'json';
  }

  // Initialises XMLHttpRequest listeners.
  _initListeners( resolve: any, reject: any, file: File ) {
    const xhr = this.xhr;
    const baseUrl = CountryService.axiosIns.defaults.baseURL
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${ file.name }.`;

    if (!xhr) return

    xhr.addEventListener( 'error', () => {
      reject(genericErrorText)
    } );
    xhr.addEventListener( 'abort', () => reject() );
    xhr.addEventListener( 'load', () => {
      const response = xhr.response;

      if ( !response || response.error ) {
        const errMsg = response && response.error ? response.error.message : genericErrorText
        return reject( errMsg );
      }

      // If the upload is successful, resolve the upload promise with the server image URL.
      resolve( {
        default: `${baseUrl}/files/${response.file_location}/${response.filename}`
      } );
    } );

    if ( xhr.upload ) {
      xhr.upload.addEventListener( 'progress', evt => {
        if ( evt.lengthComputable ) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      } );
    }
  }

  // Prepares the data and sends the request.
  _sendRequest( file: File ) {
    // Prepare the form data.
    const data = new FormData();

    data.append( 'file', file );

    // Send the request.
    this.xhr?.send( data );
  }
}

export function PurpleDocsUploadAdapterPlugin(editor: any ) {
  const baseUrl = CountryService.axiosIns.defaults.baseURL
  const url = `${baseUrl}/admin/purple-docs/templates/upload-image`
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader: any ) => {
    return new MyUploadAdapter( loader, url );
  };
}

export function TheHubArticleUploadAdapterPlugin(editor: any) {
  const baseUrl = CountryService.axiosIns.defaults.baseURL
  const url = `${baseUrl}/the-hub/articles/image`
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader: any ) => {
    return new MyUploadAdapter( loader, url );
  };
}

